.el-form-item
  margin-bottom: 20px

  &.is-error
    .date-picker input,
    .el-input__inner,
    .el-input__inner:focus,
    .el-textarea__inner,
    .el-textarea__inner:focus,
    .el-radio-button__inner,
    .el-radio-button__inner:focus
      border-color: var(--border-error-default)

  &__label
    font-size: var(--font-size)
    line-height: 1.25
    font-weight: bold
    color: var(--dark)
    position: relative

  &__content
    line-height: 1
    min-height: 40px

  &__error
    color: var(--red-attention)
    position: relative

  .el-input
    &.is-error
      .date-picker input,
      .el-input__inner,
      .el-input__inner:focus,
      .el-textarea__inner,
      .el-textarea__inner:focus
        border-color: var(--red-attention)

    .el-input__inner
      &:focus
        border-color: var(--ultra-light)

  &.input-short
    .el-form-item__content
      display: flex
      align-items: center

      .el-input
        width: 64px
        flex-shrink: 0

        .el-input__inner
          text-align: center

      .input-hint
        margin-left: 16px
        margin-top: 0

  &.is-required
    .checkbox-wrap
      .el-checkbox__label
        &:after
         content: '*'
         color: var(--red-attention)
         font-weight: bold

  .checkbox-with-bg
    .el-form-item__content
      line-height: 1.25

    .el-checkbox
      width: auto
      font-weight: bold
      background: var(--white-dark)
      border-radius: var(--input-border-radius)
      padding: 8px 16px

    .description
      margin-left: 16px
      color: var(--gray-dark)

  .date-picker
    position: relative

    &:after
      content: ''
      position: absolute
      background: url("~@/static/calendar.svg")
      display: block
      width: 12px
      height: 14px
      background-size: contain
      top: 50%
      transform: translateY(-50%)
      right: 22px

    &[disabled]
      &:before
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        cursor: not-allowed
        z-index: 1

      input
        background-color: var(--disabled-background)
        border-color: var(--disabled-border)
        color: var(--disabled-text)

    &.left-align-icon
      &:after
        left: 14px
        right: auto

      input
        padding-left: 42px

    input
      background: var(--white)
      border-radius: var(--input-border-radius)
      border: 2px solid var(--ultra-light)
      color: var(--dark)
      font-family: var(--font-family)
      font-size: 16px
      font-weight: normal
      line-height: 1.25
      height: 36px
      width: 100%

      &:focus
        box-shadow: none

  .el-input__inner
    font-size: 16px
    font-weight: normal
    line-height: 1.25
    height: 36px

    &::placeholder
      color: var(--gray)
      font-size: 16px
      font-weight: normal


  .select-wrapper
    display: flex

    .el-input__prefix
      left: 2px
      display: flex
      align-items: center

      & > .logo
        width: 20px
        height: 20px
        margin: 0 12px
        border-radius: var(--input-border-radius)

    .el-select
      width: 100%

      .el-select__tags
        .el-select__input
          font-family: var(--font-family)
          color: var(--dark)

      &.has-prefix
        .el-input__inner
          padding-left: 44px

    .el-select-dropdown
      left: 0!important

  .el-textarea__inner
    &::placeholder
      color: var(--gray)
      font-size: 16px
      font-weight: normal

  .input-hint
    margin-top: 8px
    color: var(--gray-dark)
    line-height: 1.25

.slim-form
  .el-form-item
    .el-input__inner, .date-picker input
      height: 32px
      font-size: 14px
      width: 100%

    .date-picker.left-align-icon
      input
        padding-left: 32px
